.experience-section {
  position: relative;
  width: 100%;
  height: 100vh;
}

.experience-section::before {
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  position: absolute;
  opacity: 0.1;
  background: url(../../assets/images/experience.bg.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.experience-content {
  position: relative;
  width: 60%;
  margin: 0 auto;
}

.title-section {
  font-family: var(--font-mono);
}

.title-section h2 {
  border-bottom: 1px solid var(--lightest-slate);
  width: 180px;
  color: var(--white);
}

.react-tabs {
  display: flex;
}

.react-tabs__tab-list {
  margin: 20px;
}

.react-tabs__tab-list li {
  display: flex;
  align-items: center;
  height: var(--tab-height);
  padding: 2px 10px;
  border-left: 2px solid var(--lightest-navy);
  color: var(--light-slate);
  font-family: var(--font-mono);
  font-size: var(--fz-sm);
  cursor: pointer;
  white-space: nowrap;
}

.react-tabs__tab-panel {
  margin: 0 20px;
}

.react-tabs__tab-list li:focus {
  color: var(--green);
  border-color: var(--green);
}

.react-tabs__tab-list li:hover {
  color: var(--green);
  background-color: var(--lightest-navy);
  border-color: var(--green);
} 

.react-tabs__tab-list li.react-tabs__tab--selected {
  color: var(--green);
  border-color: var(--green);
}

.experience-description {
  width: 100%;
  height: auto;
}

.experience-title h3 {
  margin-bottom: 2px;
  font-size: var(--fz-xxl);
  font-weight: 500;
  color: var(--white);
  font-family: var(--font-sans);
}

.company {
  color: var(--green);
}

.company:hover {
  border-bottom: 1px solid var(--green);
}

.date {
  margin-bottom: 25px;
  color: var(--light-slate);
  font-family: var(--font-sans);
  font-size: var(--fz-xs);
}

.experience-details {
  font-size: var(--fz-md);
  font-family: var(--font-sans);
  color: var(--lightest-slate);
  display: flex;
}

.experience-details p {
  text-align: left;
}

.diamond {
  color: var(--green);
  margin-right: 10px;
}

@media (max-width: 768px) {
  .experience-section::before {
    background: none;
  }

  .experience-section {
    height: 100%;
  }

  .experience-list {
    width: 100%;
  }

  .experience-content {
    width: 100%;
  }

  .react-tabs {
    display: block;
  }

  .react-tabs__tab-list {
    display: flex;
    margin: 0;
  }

  .react-tabs__tab-list li {
    border-left: none;
    border-bottom: 2px solid var(--lightest-navy);
  }

  .react-tabs__tab-panel {
    width: 450px;
    margin-left: 30px;
  }
}