.contact-section::before {
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  position: absolute;
  opacity: 0.1;
  background: url(../../assets/images/contact.webp);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.contact-section {
  position: relative;
  width: 100%;
  height: 100vh;
}

.title-section {
  position: relative;
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
}

.contact-section .title-section h2 {
  width: 200px;
}

.contact-section p {
  color: var(--lightest-slate);
  font-size: var(--fz-xl);
  font-family: var(--font-sans);
}

.contact-section a {
  color: var(--green);
  border: 1px solid var(--green);
  border-radius: var(--border-radius);
  padding: 1.25rem 1.75rem;
  font-size: var(--fz-xl);
  font-family: var(--font-mono);
  cursor: pointer;
  margin-top: 50px;
}

.contact-section a:hover {
  background-color: var(--green-tint);
  font-size: var(--fz-xxl);
}