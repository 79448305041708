body {
  margin: 0;
  padding: 0;
  color: var(--white);
  font-size: 14px;
  background-color: #0A192F;
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.app {
  background-color: #0A192F;
}

body::-webkit-scrollbar {
  width: 0;
  height: 0;
}

html {
  scroll-behavior: smooth;
}

:root {
  --dark-navy: #020c1b;
  --navy: #0a192f;
  --light-navy: #112240;
  --lightest-navy: #233554;
  --navy-shadow: #020c1bb3;
  --dark-slate: #495670;
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green: #64ffda;
  --green-tint: #64ffda1a;
  --font-sans: "Calibre","Inter","San Francisco","SF Pro Text",system-ui,sans-serif;
  --font-mono: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;
  --fz-xxs: 12px;
  --fz-xs: 13px;
  --fz-sm: 14px;
  --fz-md: 16px;
  --fz-lg: 18px;
  --fz-xl: 20px;
  --fz-xxl: 22px;
  --fz-heading: 32px;
  --border-radius: 4px;
  --tab-height: 60px;
  --tab-width: 120px;
}
