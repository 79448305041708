.about-section {
  position: relative;
  width: 100%;
  height: 100vh;
}

.about-section::before {
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  position: absolute;
  opacity: 0.1;
  background: url(../../assets/images/pc-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.about-content {
  position: relative;
  width: 80%;
  margin: 0 auto;
  padding-top: 50px;
}

.about-me {
  font-family: var(--font-mono);
  font-size: var(--fz-sm);
  counter-increment: item 1;
}

.skills {
  color: var(--lightest-slate);
}

.about-me h2 {
  border-bottom: 1px solid var(--lightest-slate);
  width: 180px;
  color: var(--white);
}

.counter {
  color: var(--green);
  padding-right: 2px;
}

.about-me-description {
  margin: 20px 0px 0px;
  width: 65%;
  color: var(--lightest-slate);
  font-family: var(--font-sans);
  font-size: var(--fz-xxl);
}

.about-me-skills {
  display: flex;
  justify-content: space-evenly;
}

.about-me-skills ul {
  margin: 20px 0;
  text-align: justify;
}

.about-me-skills ul li {
  list-style: none;
  padding-bottom: 10px;
}

.list-title {
  color: var(--green);
  margin-bottom: 10px;
  text-decoration: underline;
  font-size: var(--fz-xl);
}

.flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.profile-image {
  border-radius: 10px;
  opacity: 0.7;
  cursor: pointer;
}

.profile-image-mobile {
  display: none;
}

.profile-image:hover {
  opacity: 1;
  border-radius: 5px;
  border: 1px solid var(--green);
}

@media (max-width: 980px) {

  .about-section {
    height: 100%;
  }

  .about-section::before {
    height: 100%;
    background: none;
  }

  /* .about-content {
    width: 100%;
    padding-left: 15%;
  } */

  .about-section .flex {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  .about-me-description {
    margin: 0;
    width: 100%;
    color: var(--light-slate);
  }

  .profile-image {
    display: none;
  }

  .profile-image-mobile {
    display: block;
    border-radius: 10px;
    opacity: 0.7;
    cursor: pointer;
    margin: 0 auto;
  }

  .profile-image-mobile:hover {
    opacity: 1;
    border-radius: 5px;
    border: 1px solid var(--green);
  }
}